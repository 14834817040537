<template>
	<div>
		<h1 class="heading">Бланк регистрации нового контента для загрузки на сайт консультационного центра</h1>
		<fieldset>
			<legend>Выберите тип материала, который хотите загрузить:</legend>
			<div class="radio-group">
				<label for="material">
					<input type="radio" name="material_type" id="material" value="material" v-model="materialType">
					Материал
				</label>
				<label for="rla">
					<input type="radio" name="material_type" id="rla" value="rla" v-model="materialType">
					НПА
				</label>
				<label for="faq">
					<input type="radio" name="material_type" id="faq" value="faq" v-model="materialType">
					Вопрос-ответ с экспертом
				</label>
			</div>
		</fieldset>
		<new-material-form :material-type="materialType"/>
	</div>
</template>

<script>
import NewMaterialForm from '../../../components/forms/NewMaterialForm.vue'
export default {
	name: 'NewMaterial',
	components: { NewMaterialForm },
	data() {
		return {
			materialType: '',
		}
	},
}
</script>

<style scoped>
fieldset {
	margin: 0 auto;
	max-width: 800px;
	margin-bottom: 30px;
	border-color: var(--dark-blue);
	border-radius: 10px;
}

fieldset legend {
	color: var(--dark-blue);
	font-weight: 700;
}

.radio-group {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	grid-template-rows: 1fr;
}
</style>
